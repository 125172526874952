import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import { loaduser, register } from '../../actions/auth';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Register.css';
import axios from 'axios';

function Register({
  onCloseClick,
  setAlert,
  register,
  isAuthenticated,
  loaduser,
}) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
  });

  const { name, email, password, phone } = formData;

  const sendMail = async () => {
    let data = {
      name: name,
      email: email,
    };

    await axios.post(
      'https://api.careernaksha.com/api/communityRegisterMail',
      data
    );
  };

  const onChangetext = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const registerHandler = async () => {
    register({ name, email, password, phone });
    sendMail();
    onCloseClick();
  };
  return (
    <Card
      sx={{ minWidth: 250, marginTop: 5 }}
      style={
        {
          // boxShadow: "0px 0px 4px rgb(75, 73, 73)",
        }
      }>
      <h1 className="register-heading">Register</h1>
      <CardContent>
        <TextField
          id="outlined-basic"
          label="Enter Name"
          variant="outlined"
          value={name}
          onChange={(e) => onChangetext(e)}
          name="name"
          style={{ marginBottom: 10, width: '100%' }}
        />
        <TextField
          id="outlined-basic"
          label="Enter Email"
          name="email"
          variant="outlined"
          value={email}
          onChange={(e) => onChangetext(e)}
          style={{ marginBottom: 10, width: '100%' }}
        />
        <TextField
          id="outlined-basic"
          label="Enter Phone No."
          name="phone"
          value={phone}
          onChange={(e) => onChangetext(e)}
          variant="outlined"
          style={{ marginBottom: 10, width: '100%' }}
        />
        <TextField
          id="outlined-basic"
          label="Enter Password"
          variant="outlined"
          name="password"
          value={password}
          type="password"
          onChange={(e) => onChangetext(e)}
          style={{ marginTop: 3, width: '100%' }}
        />
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() => registerHandler()}
          style={{ color: '#42b7a7' }}>
          Submit
        </Button>
        <Button
          size="small"
          onClick={() => onCloseClick()}
          style={{ color: '#42b7a7' }}>
          Close
        </Button>
      </CardActions>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  loaduser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

export default connect(mapStateToProps, { setAlert, register, loaduser })(
  Register
);
