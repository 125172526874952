import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import './Members.css';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getAllUsersAction } from '../../redux-action-reducer/getusers';
import axios from 'axios';
import { backendurl } from '../../environment';

export default function Members() {
  const dispatch = useDispatch();

  const [userCount, setUserCount] = useState(0);

  const { loading, allusers } = useSelector((state) => state.allusers);

  const getUserCount = async () => {
    await axios
      .get(`${backendurl}/api/community/users/getUsersCount`)
      .then((res) => {
        if (res.status === 200) setUserCount(res.data.docCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    dispatch(getAllUsersAction());
    getUserCount();
  }, [dispatch]);

  if (!loading && allusers) {
    const alluser = allusers.slice(0, 20);
    return (
      <>
        <List
          sx={{
            width: '100%',
            maxWidth: 310,
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
            marginTop: 3,
            marginLeft: '0.5em',
            '& ul': { padding: 0 },
          }}
          subheader={
            <h5
              style={{
                color: '#42b7a7',
                textAlign: 'center',
                backgroundColor: '#EAEAEA',
                padding: '0.6em',
              }}>
              {userCount} Members
            </h5>
          }
          className="members-list">
          {alluser.map((value) => {
            const labelId = `checkbox-list-secondary-label-${value}`;
            return (
              <Link
                to={`/counsellor/${value.slug}`}
                style={{ color: 'black' }}
                key={value._id}>
                <ListItem className="list-item-custom">
                  <ListItemButton className="list-item-button">
                    <ListItemAvatar>
                      <Avatar
                        className="avatar"
                        alt={`Avatar n°${value + 1}`}
                        src={value.avatar}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: '16px' }}
                      id={labelId}
                      primary={value.name}
                    />
                    {value.isVerified && (
                      <ListItemIcon>
                        <VerifiedIcon color="primary" />
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                </ListItem>
              </Link>
            );
          })}
          <div
            style={{
              marginTop: '8px',
              textAlign: 'center',
            }}>
            <Link
              to=""
              style={{
                color: '#42b7a7',
              }}>
              View More...
            </Link>
          </div>
        </List>
      </>
    );
  } else {
    return <div></div>;
  }
}
