import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import { login } from '../../actions/auth';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Mob.css';

function MobLogin({ login, history, auth: { user } }) {
  const [showLoginCard, setshowLoginCard] = useState(true);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [user]);

  const onChangetext = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const loginHandler = async () => {
    login(email, password);
    if (user) {
      history.push('/');
    }
  };
  const forgotHandler = () => {
    history.push('/forget-password/');
  };

  return (
    <div className="mob">
      {showLoginCard && (
        <Card
          sx={{ width: 1500, marginTop: 5, minHeight: 400, maxHeight: 750 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <CardContent>
            <TextField
              id="outlined-basic"
              label="Enter Email"
              variant="outlined"
              name="email"
              value={email}
              onChange={(e) => onChangetext(e)}
              style={{
                marginTop: '5%',
                width: '90%',
                marginLeft: '5%',
              }}
            />
            <TextField
              id="outlined-basic"
              label="Enter Password"
              name="password"
              variant="outlined"
              style={{ marginTop: '5%', width: '90%', marginLeft: '5%' }}
              type="password"
              value={password}
              onChange={(e) => onChangetext(e)}
            />
          </CardContent>
          <CardActions></CardActions>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Button
              variant="outlined"
              onClick={() => {
                loginHandler();
              }}>
              Login
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                forgotHandler();
              }}
              style={{ marginLeft: '20px' }}>
              Forgot
            </Button>
          </div>

          <p
            style={{
              display: 'flex',
              margin: '1em',
              justifyContent: 'center',
            }}>
            Don't have an account?{' '}
            <Link
              style={{
                textDecoration: 'none',
                color: '#42b7a7',
                paddingLeft: '0.5em',
              }}
              to="/register">
              Register
            </Link>
          </p>
          <p
            style={{
              display: 'flex',
              margin: '1em 0 2em',
              justifyContent: 'center',
            }}>
            Benefits of our community <br></br>-Networking <br></br>-Discussions
            <br></br>-Doubts/queries solutions <br></br>-Discussion Forum{' '}
            <br></br>-Events (Online/Offline) <br></br>
            -News/Announcements/Notifications <br></br>-Request for tools &
            services <br></br>-Expansion of business/center/private practice
            <br></br>-Referrals/clients <br></br>-Solve problems together
          </p>
        </Card>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

MobLogin.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { login })(MobLogin);
