// ! CONTANTS

import axios from 'axios';

const USER_REQUEST = 'USER_REQUEST';
const USER_SUCCESS = 'USER_SUCCESS';
const USER_FAIL = 'USER_FAIL';

// ! REDUCERS

export default function (state = {}, action) {
  switch (action.type) {
    case USER_REQUEST:
      return { loading: true, member: {} };
    case USER_SUCCESS:
      return { loading: false, member: action.payload };
    case USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// ! ACTIONS

export const specificUserDetailAction = (slug) => async (dispatch) => {
  try {
    dispatch({ type: USER_REQUEST });

    if(slug !== undefined){
      const res = await axios.get(
        `https://api.careernaksha.com/api/community/users/getUsers/${slug}`
      );
      dispatch({ type: USER_SUCCESS, payload: res.data });
    }

    

  
  } catch (error) {
    dispatch({
      type: USER_FAIL,
      payload: {
        msg: 'SERVER ERROR',
        status: 500,
      },
    });
  }
};
