import React, { useState } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import { loaduser, register } from "../../actions/auth";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import "./Mob.css";

function MobRegister({
	onCloseClick,
	setAlert,
	register,
	isAuthenticated,
	loaduser,
	history,
}) {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		password: "",
		phone: "",
	});

	
	

	const { name, email, password, phone } = formData;


	const sendMail = async() => {
		let data = {
		  name:name,
		  email:email,
		}
	
		let data_mail = await axios.post('https://api.careernaksha.com/api/communityRegisterMail',data);
	  }

	const onChangetext = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const registerHandler = async () => {
		register({ name, email, password, phone });
		sendMail();
		history.push("/");
		onCloseClick();
	};
	return (
		<div className="mob">
			<Card sx={{ minWidth: 250, marginTop: 5 }}>
				<CardContent>
					<TextField
						id="outlined-basic"
						label="Enter Name"
						variant="outlined"
						value={name}
						onChange={(e) => onChangetext(e)}
						name="name"
						style={{ marginTop: "5%", width: "90%", marginLeft: "5%" }}
					/>
					<TextField
						id="outlined-basic"
						label="Enter Email"
						name="email"
						variant="outlined"
						value={email}
						onChange={(e) => onChangetext(e)}
						style={{ marginTop: "5%", width: "90%", marginLeft: "5%" }}
					/>
					<TextField
						id="outlined-basic"
						label="Enter Phone"
						name="phone"
						variant="outlined"
						value={phone}
						onChange={(e) => onChangetext(e)}
						style={{ marginTop: "5%", width: "90%", marginLeft: "5%" }}
					/>
					<TextField
						id="outlined-basic"
						label="Enter Password"
						variant="outlined"
						name="password"
						value={password}
						type="password"
						onChange={(e) => onChangetext(e)}
						style={{ marginTop: "5%", width: "90%", marginLeft: "5%" }}
					/>
				</CardContent>
				<CardActions>
					<Button
						variant="outlined"
						style={{ margin: "auto" }}
						onClick={() => registerHandler()}
					>
						Register
					</Button>
				</CardActions>
				<p
					style={{
						display: "flex",
						justifyContent: "center",
						margin: "1em 0 2em",
					}}
				>
					Don't have an account?{" "}
					<Link
						style={{
							textDecoration: "none",
							color: "#42b7a7",
							paddingLeft: "0.5em",
						}}
						to="/login"
					>
						Login
					</Link>
				</p>
			</Card>
		</div>
	);
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

MobRegister.propTypes = {
	setAlert: PropTypes.func.isRequired,
	register: PropTypes.func.isRequired,
	loaduser: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
};

export default connect(mapStateToProps, { setAlert, register, loaduser })(MobRegister);
