import axios from "axios";

// ! CONSTANTS

const USER_PRIVATE_POST_REQUEST = "USER_PRIVATE_POST_REQUEST";
const USER_PRIVATE_POST_SUCCESS = "USER_PRIVATE_POST_SUCCESS";
const USER_PRIVATE_POST_ERROR = "USER_PRIVATE_POST_ERROR";

// ! Reducer

export default function (state = {}, action) {
  switch (action.type) {
    case USER_PRIVATE_POST_REQUEST:
      return { privatePostloading: true, privatePost: {} };
    case USER_PRIVATE_POST_SUCCESS:
      return {
        privatePostloading: false,
        privatePost: action.payload,
      };
    case USER_PRIVATE_POST_ERROR:
      return { privatePostloading: false, error: action.payload };

    default:
      return state;
  }
}

// ! action

export const userPrivatePostAction = (screen) => async (dispatch) => {
  try {
    dispatch({ type: USER_PRIVATE_POST_REQUEST });

    const config = {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    };

    if (screen == "PROFILE") {
      const { data } = await axios.get(
        `https://api.careernaksha.com/api/community/posts/myPrivate`,
        config
      );
      dispatch({ type: USER_PRIVATE_POST_SUCCESS, payload: data });
    } else {
      const { data } = await axios.get(
        `https://api.careernaksha.com/api/community/posts/private`,
        config
      );
      dispatch({ type: USER_PRIVATE_POST_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({
      type: USER_PRIVATE_POST_ERROR,
      payload: {
        msg: "Server Error",
        status: error.response.status,
      },
    });
  }
};
