import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import auth from "../../reducers/auth";
import TextField from '@mui/material/TextField';
import axios from 'axios';

import "./refer.css";

const Refer = () => {
  const [referPopup, setReferPopup] = useState(false);
  const [actionPopupActive, setActionPopupActive] = useState(false);
  const [triggeredActionPopup, setTriggeredActionPopup] = useState("");
  const [invitingParty, setinvitingParty] = useState('');
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const history = useHistory();

  const updatedInvited = async() => {
    let data = {
      id:user.email
    }
    console.log(data);
    let data_inv = await axios.post('https://api.careernaksha.com/api/community/users/updateInvited',data);

  }

  const sendReferralMail = async() => {
    let data = {
      name:user.name,
      link:`https://community.careernaksha.com/refer/${
        user.slug
      }`,
      referEmail:invitingParty
    }

    let data_mail = await axios.post('https://api.careernaksha.com/api/communityReferralMail',data);
    updatedInvited();
  }

  useEffect(() => {
    if (user == null) {
      history.push("/");
    }
  }, []);

  return (
    <div className="refer-scss">
      <div className="hero-container">
        <div className="hero-cover">
          <div>
            <h2>BE GOOD TO SOMEONE. GET REWARDED.</h2>
            <p>Refer your friends and earn unlimited points & rewards.</p>
            <button onClick={() => setReferPopup(true)}>Refer Now</button>
          </div>
          <img src="./img/refer/Main illustration.png" alt="" />
        </div>
        <div className="hero-action">
          <div className="hero-sub-action">
            <h5
              onClick={() => {
                setTriggeredActionPopup("INVITED");
                setActionPopupActive(true);
              }}
            >
              View All
            </h5>
            <div className="detail">
              <img src="./img/refer/Register.png" alt="" />
              <div>
                <h2>{user && user.referEmailCount}</h2>
                <h3>INVITED</h3>
              </div>
            </div>
          </div>
          <div className="hero-sub-action">
            <h5
              onClick={() => {
                setTriggeredActionPopup("REGISTERED");
                setActionPopupActive(true);
              }}
            >
              View All
            </h5>
            <div className="detail">
              <img src="./img/refer/Group 2.png" alt="" />

              <div>
                <h2>{user && user.registeredUsers.length}</h2>
                <h3>REGISTERED</h3>
              </div>
            </div>
          </div>
          <div className="hero-sub-action">
            <h5
              onClick={() => {
                setTriggeredActionPopup("ENROLLED");
                setActionPopupActive(true);
              }}
            >
              View All
            </h5>
            <div className="detail">
              <img src="./img/refer/Enrolled.png" alt="" />

              <div>
                <h2>0</h2>
                <h3>ENROLLED</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="how-it-work-container">
        <h2>How it works</h2>
        <p> Your friend gets 10 points on their signup, and you get 100 points for successful referral.</p>
        <div className="how-it-work">
          <div className="section">
            <img src="./img/refer/Group.png" alt="" />
            <h2>Refer a Friend</h2>
            <p>
              Chat, tweet, email, or share. Pick your channel and share your
              referral code{" "}
            </p>
          </div>
          <div className="section">
            <img src="./img/refer/Enroll 2.png" alt="" />
            <h2>Your Friend Enrols</h2>
            <p>
              Your friend enrols in any CareerNaksha career guidance program
              using your referral code
            </p>
          </div>
          <div className="section">
            <img src="./img/refer/Reward.png" alt="" />
            <h2>You Both Earn Rewards</h2>
            <p>
            Your friend gets 10 points on their signup, and you get 100 points for successful referral.
            </p>
          </div>
        </div>
      </div>

      <div className="terms-conditions">
        <h2>Terms & Conditions:</h2>
        <p>1. You cannot refer yourself</p>
        <p>2. Offer valid on authentic and paid signups only</p>
        <p>3. The offer is valid only for new and non-enrolled users</p>
        <p>
          4. The voucher is tendered when your referral has signed up for a paid
          career planning service
        </p>
        <p>5. Offer may vary at the discretion of the company</p>
        <p>6. All other website terms apply</p>
      </div>

      {referPopup && (
        <div className="refer-popup-container">
          <div className="refer-popup">
            <img src="./img/refer/Main illustration.png" alt="" />
            <h3>WE'D LIKE TO MEET YOUR FRIENDS!</h3>
            <p>
            Your friend gets 10 points on their signup, and you get 100 points for successful referral.
            </p>
            <h2>REFERRAL LINK</h2>
            <input
              type="text"
              value={`https://community.careernaksha.com/refer/${
                user.slug
              }`}
            />
            <div>
              {/* <button>Copy Referral Link</button> */}
              <button onClick={() => setReferPopup(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {actionPopupActive && (
        <div className="refer-popup-container">
          <div className="refer-popup">
            <h5>Lets Invite Your Friends</h5>

            <input  
              value={invitingParty}
              name='invitingParty'
              placeholder="Enter Email"
              onChange={(e) => {setinvitingParty(e.target.value)}}
              id="outlined-basic" label="Email Id" />   

            <button
              onClick={() => {
                setActionPopupActive(false);
                sendReferralMail();
              }}
            >
              Send a Referral E-Mail
            </button>
            <button
              onClick={() => {
                setActionPopupActive(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Refer;
