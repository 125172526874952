const percentageCalculation = (part,whole) => { 
    return Math.round(part/whole * 100)
  }

export const levels = (points) => {
if (points < 50){
    return {previousLevel : 1, previousLevelRemark : "in progress...", currentLevel : 1, currentLevelPercentage: percentageCalculation(points, 50), upcomingLevel : 2, upcomingLevelPoint : 500, inlineBar : [points, 50]}
} else if (points < 500){
    return {previousLevel : 1, previousLevelRemark : "Completed", currentLevel : 2, currentLevelPercentage: percentageCalculation(points, 500), upcomingLevel : 3, upcomingLevelPoint : 2000, inlineBar : [points, 500]}
} else if (points < 2000){
    return {previousLevel : 2, previousLevelRemark : "Completed", currentLevel : 3, currentLevelPercentage: percentageCalculation(points, 2000), upcomingLevel : 4, upcomingLevelPoint : 5000, inlineBar : [points, 2000]}
} else if (points < 5000){
    return {previousLevel : 3, previousLevelRemark : "Completed", currentLevel : 4, currentLevelPercentage: percentageCalculation(points, 5000), upcomingLevel : 5, upcomingLevelPoint : 10000, inlineBar : [points, 5000]}
} else if (points < 10000){
    return {previousLevel : 4, previousLevelRemark : "Completed", currentLevel : 5, currentLevelPercentage: percentageCalculation(points, 10000), upcomingLevel : 6, upcomingLevelPoint : 25000, inlineBar : [points, 10000]}
} else if (points < 25000){
    return {previousLevel : 5, previousLevelRemark : "Completed", currentLevel : 6, currentLevelPercentage: percentageCalculation(points, 25000), upcomingLevel : 7, upcomingLevelPoint : 50000, inlineBar : [points, 25000]}
}
}