import React, { useEffect, useState } from 'react';
import Pagination from '../../components/layout/Pagination';
import './admin.css';

// ? React-Redux
import { getPosts, adminPannelAction } from '../../actions/post';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { specificUserDetailAction } from '../../redux-action-reducer/specific-user';
import PropTypes from 'prop-types';

const AdminPanel = ({ auth, history }) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(20);

  const { loading, posts } = useSelector((state) => state.post);

  const checkVerified = () => {
    const token = localStorage.getItem('token');
    if (auth.user?.isAdmin === false || token == null) {
      history.push('/');
    }
  };

  useEffect(() => {
    checkVerified();
    dispatch(getPosts());
    dispatch(specificUserDetailAction()); // actionCreator
  }, [dispatch]);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div style={{}}>
      <div className="row">
        <div className="col col-md-6">
          <Link
            to="/admin/r"
            className="navigation-btn"
            style={{ marginLeft: '10px' }}>
            <p>Manage Verification Requests</p>
          </Link>
          <Link
            to="/admin/orders"
            className="navigation-btn"
            style={{ marginLeft: '10px' }}>
            <p>List Orders</p>
          </Link>
          <Link
            to="/admin/verifiedUsers"
            className="navigation-btn"
            style={{ marginLeft: '10px' }}>
            <p>List Verified Users</p>
          </Link>
          <Link
            to="/admin/l"
            className="navigation-btn"
            style={{ marginLeft: '10px', marginLeft: '30px' }}>
            <p>See Leads</p>
          </Link>
        </div>
        <div className="col col-md-6">
          <Link
            to="/admin/points"
            className="navigation-btn"
            style={{ marginLeft: '10px' }}>
            <p>Credit Points</p>
          </Link>
          <Link
            to="/admin/verify"
            className="navigation-btn"
            style={{ marginLeft: '10px' }}>
            <p>Unverify A User</p>
          </Link>
          <Link
            to="/admin/certRequests"
            className="navigation-btn"
            style={{ marginLeft: '10px' }}>
            <p>Cert. Requests</p>
          </Link>
        </div>
      </div>

      {!loading ? (
        <div>
          <table>
            <thead>
              <tr>
                <th>Title</th>
                {/* <th>Body</th> */}
                {/* <th>Author</th> */}
                <th>isImp:</th>
                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts.map((post) => (
                <tr key={post._id}>
                  <Link to={`/post/${post.slug}`}>
                    <td>{post.title}</td>
                  </Link>
                  {/* <td>{post.text}</td>
                <td>{post.name}</td> */}
                  <td
                    onClick={() =>
                      dispatch(adminPannelAction(`${post._id}`, 'UPDATE'))
                    }>
                    {post.isImp ? (
                      <i className="fa-solid fa-check"></i>
                    ) : (
                      <i className="fa-solid fa-xmark"></i>
                    )}
                  </td>
                  <td
                    onClick={() =>
                      dispatch(adminPannelAction(`${post._id}`, 'DELETE'))
                    }>
                    <i className="fa-solid fa-ban"></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            postsPerPage={postPerPage}
            totalPosts={posts.length}
            currentPage
            paginate={paginate}
          />
        </div>
      ) : (
        'Loading'
      )}
    </div>
  );
};

AdminPanel.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdminPanel);
