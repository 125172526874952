import React,{useState,useEffect} from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import axios from 'axios';
import { setAlert } from '../../actions/alert';
import { useDispatch,connect,useSelector} from "react-redux";
import PropTypes from "prop-types";


function AdminVerifyAccount({auth,history}) {
    const [email, setemail] = useState('');
    const [userdb, setuserDb] = useState(null);
    const dispatch = useDispatch();

    const { isAuthenticated, user } = useSelector((state) => state.auth);

    const checkVerified = () => {
      console.log(auth.isAuthenticated);
      const token = localStorage.getItem('token');
      console.log(token)
        if(auth.user?.isAdmin === false || token == null){
          history.push("/");
        }
      }


      useEffect(() => {
       checkVerified();
      }, [auth])
      

    const verifyAccount = async () => {
        let data_api = {
            email:email
        }

        let data = await axios.post('https://api.careernaksha.com/api/community/users/alterVerificationStatus',data_api);

        if(data.data){
            setuserDb(data.data);
            console.log(data.data);
            console.log(userdb);
            dispatch(setAlert('Verification Status Changed','success'));
            setemail('');
        }
    }
    
  return (
    
    <div style={{display:'flex',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
        <h2 style={{marginTop:'20px',textAlign:'center'}}>Undo Verification</h2>
        <div style={{display:'flex',justifyContent:'center',marginTop:'40px'}}>
            <TextField
            value={email}
            onChange={(e) => setemail(e.target.value)}  
            InputLabelProps={{
      style: { color: '#000' }, 
   }} style={{width:'60%',background:'#fff'}} id="outlined-basic" label="Email Id"  />   
      
        </div>
        <div style={{display:'flex',justifyContent:'center'}}>

<Button variant="text" style={{marginTop:'20px',color:'#000'}} onClick={() => verifyAccount()}>SUBMIT</Button>


</div>
    </div>
  )
}
AdminVerifyAccount.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps)(AdminVerifyAccount);