// ! CONTANTS

import axios from 'axios';
import { backendurl } from '../environment';

const USERS_REQUEST = 'USERS_REQUEST';
const USERS_SUCCESS = 'USERS_SUCCESS';
const USERS_FAIL = 'USERS_FAIL';

// ! REDUCERS

export default function (state = {}, action) {
  switch (action.type) {
    case USERS_REQUEST:
      return { loading: true, allusers: {} };
    case USERS_SUCCESS:
      return { loading: false, allusers: action.payload };
    case USERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// ! ACTIONS

export const getAllUsersAction = () => async (dispatch) => {
  try {
    dispatch({ type: USERS_REQUEST });

    const res = await axios.get(`${backendurl}/api/community/users/getUsers`);

    dispatch({ type: USERS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: USERS_FAIL,
      payload: {
        msg: 'SERVER ERROR',
        status: 500,
      },
    });
  }
};
