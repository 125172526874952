import { combineReducers } from 'redux';

import alert from './alert';
import auth from './auth';
import profile from './profile';
import post from './post';

import userPost from '../redux-action-reducer/user-post';
import userPrivatePost from '../redux-action-reducer/user-post-private';
import memberProfile from '../redux-action-reducer/specific-user';
import userProduct from '../redux-action-reducer/user-requests';
import allusers from '../redux-action-reducer/getusers';

export default combineReducers({
  alert,
  auth,
  profile,
  post,
  userPost,
  userPrivatePost,
  memberProfile,
  userProduct,
  allusers,
});
