import React from 'react';
import './style.css';

export const LevelStats = ({ level }) => {
  return (
    <div className="level-prograss">
      <div className="charts-container">
        <div className="full-pie-wrapper margin--right">
          <div className="label label-style-2 ">
            <h2>Level {level['previousLevel']}</h2>
            <p>{level['previousLevelRemark']}</p>
          </div>
        </div>

        <div
          className={`set-size-10 pie-wrapper progress-${level['currentLevelPercentage']} style-2`}>
          <div className="label">
            <h2>{level['currentLevelPercentage']}%</h2>
            <p>Level {level['currentLevel']}</p>
          </div>
          <div className="pie">
            <div className="left-side half-circle"></div>
            <div className="right-side half-circle"></div>
          </div>
          <div className="shadow"></div>
        </div>

        <div className="full-pie-wrapper margin--left">
          <div className="label label-style-2">
            <h2>Level {level['upcomingLevel']}</h2>
            <p>{level['upcomingLevelPoint']} Points</p>
          </div>
        </div>
      </div>
      <div className="inline-progress-bar">
        <span
          style={{ width: `${level['currentLevelPercentage']}%` }}
          className="progress"></span>
        <span className="bg"></span>
        <h2 className="inline-progress-bar-label">
          POINTS:{' '}
          <span>
            {level['inlineBar'][0]}/{level['inlineBar'][1]}
          </span>
        </h2>
      </div>
    </div>
  );
};
