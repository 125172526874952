import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { userPrivatePostAction } from '../../redux-action-reducer/user-post-private';
//
//
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { Oval } from 'react-loader-spinner';
import { Button } from '@mui/material';
import HtmlParser from 'react-html-parser';
//
const PrivatePosts = () => {
  const dispatch = useDispatch();

  const { privatePostloading, privatePost } = useSelector(
    (state) => state.userPrivatePost
  );

  useEffect(() => {
    dispatch(userPrivatePostAction('HOME'));
  }, []);

  return (
    <div>
      <div className='card mb-3 custom-card'>
        <div className='row no-gutters'>
          <div className='col-md-8'>
            <div className='card-body'>
              <h5 className='card-title' style={{ fontSize: '21px' }}>
                Welcome to Counsellors Community by CCI & CareerNaksha
              </h5>
              <p className='card-text'>
                This community is for you to practice counselling better.
                Communicate with other fellow counsellors. Ask your questions,
                provide answers and share your thoughts with the community. We
                reward the top counsellors for their unbiased knowledge & ideas.
              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <img
              src='https://ik.imagekit.io/careerNaksha/Frame_1__2__27UoD81De.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649230937446'
              className='card-img custom-img'
              style={{
                paddingTop: '40px',
                imageRendering: '-webkit-optimize-contrast',
              }}
              alt='career-naksha'
            />
          </div>
        </div>
        <span>
          <Link to='/'>
            <Button
              size='small'
              style={{
                color: '#42b7a7',
                borderRadius: '0px',
              }}
            >
              Posts
            </Button>
          </Link>
          <Link to='/create-post'>
            <Button
              size='small'
              style={{
                color: '#42b7a7',
              }}
            >
              Create
            </Button>
          </Link>

          <Button
            size='small'
            style={{
              color: '#42b7a7',
              borderRadius: '0px',
              borderBottom: '2px solid #42b7a7',
            }}
          >
            Private Posts
          </Button>
        </span>
      </div>

      {/* Hero end */}

      {privatePostloading ? (
        <div style={{ marginLeft: '40%', marginTop: '10%' }} className='loader'>
          <Oval
            ariaLabel='loading-indicator'
            height={100}
            width={100}
            strokeWidth={5}
            color='#42b76d'
            secondaryColor='#42b7a7'
          />
        </div>
      ) : (
        ''
      )}
      {!privatePostloading && privatePost ? (
        <div>
          {privatePost.map((post) => (
            <ListItem
              alignItems='flex-start'
              style={{
                borderBottom: '2px solid 	#D3D3D3',
                backgroundColor: 'white',
              }}
            >
              <ListItemAvatar>
                <img alt='Remy Sharp' src={post.avatar} style={{ objectFit: 'contain',height:'50px',width:'50px'}}/>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Link
                    to={{
                      pathname: `/post/${post.slug}`,
                    }}
                    style={{ color: '#000' }}
                  >
                    {post.title}
                  </Link>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component='span'
                      variant='body2'
                      color='text.primary'
                    >
                      {HtmlParser(post.text)}
                    </Typography>

                    <br />
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PrivatePosts;
