import React, { useEffect, useState } from 'react';
import PostCard from '../../components/PostCard';
import './profile.css';
import './post.css';
import Button from '@mui/material/Button';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { saveAs } from 'file-saver';

import { userPostAction } from '../../redux-action-reducer/user-post';
import { userPrivatePostAction } from '../../redux-action-reducer/user-post-private';
import { setAlert } from '../../actions/alert';
import { userProductAction } from '../../redux-action-reducer/user-requests';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import TextField from '@mui/material/TextField';
import { loaduser } from '../../actions/auth';
import { LevelStats } from '../../components/LevelStats/info';

import { levels } from '../../components/LevelStats/levelCalFn';
import Verified from '@mui/icons-material/Verified';
import { backendurl, dashboardurl } from '../../environment';

const Profile = ({ auth, history }) => {
  const [loadingData, setloadingData] = useState(false);
  const [showActivityPoints, setShowActivityPoints] = useState(false);

  const setDescriptionState = async () => {
    if (auth.user?.about) {
      setDescription(auth.user?.about);
    }
  };
  const setTagsState = async () => {
    if (auth.user) {
      let data_api = {
        id: auth.user._id,
      };
      let data = await axios.post(
        'https://api.careernaksha.com/api/community/users/getTags',
        data_api
      );
      if (data.data.academicCounsellor) {
        setisacademicCounsellor(true);
      }
      if (data.data.behaviouralCounsellor) {
        setisbehaviouralCounsellor(true);
      }
      if (data.data.industrialCounsellor) {
        setisindustrialCounsellor(true);
      }
      if (data.data.informationCounsellor) {
        setisinformationCounsellor(true);
      }
      if (data.data.isCareerCounsellor) {
        setisCareerCounsellor(true);
      }
      if (data.data.isClinicalCounsellor) {
        setisClinicalCounsellor(true);
      }
      if (data.data.isHrCounsellor) {
        setisHrCounsellor(true);
      }
      if (data.data.mentalCounsellor) {
        setismentalCounsellor(true);
      }
      if (data.therapyCounsellor) {
        setistherapyCounsellor(true);
      }
    }
  };
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const dispatch = useDispatch();

  const { loading, userPost } = useSelector((state) => state.userPost);
  const { userRequest } = useSelector((state) => state.userProduct);

  const { privatePostloading, privatePost } = useSelector(
    (state) => state.userPrivatePost
  );
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const [isCareerCounsellor, setisCareerCounsellor] = useState(false);
  const [isClinicalCounsellor, setisClinicalCounsellor] = useState(false);
  const [isHrCounsellor, setisHrCounsellor] = useState(false);
  const [isindustrialCounsellor, setisindustrialCounsellor] = useState(false);
  const [isbehaviouralCounsellor, setisbehaviouralCounsellor] = useState(false);
  const [isacademicCounsellor, setisacademicCounsellor] = useState(false);
  const [isinformationCounsellor, setisinformationCounsellor] = useState(false);
  const [istherapyCounsellor, setistherapyCounsellor] = useState(false);
  const [ismentalCounsellor, setismentalCounsellor] = useState(false);
  const [description, setDescription] = useState('');

  const [updateProfile, setUpdateProfile] = useState(false);

  const [isAlreadyVerified, setIsAlreadyVerified] = useState(false);

  const hashCode = function (s) {
    return s.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  };

  useEffect(() => {
    dispatch(userPrivatePostAction('PROFILE'));
    dispatch(userPostAction());
    dispatch(userProductAction());
    setDescriptionState();
    setTagsState();
    if (auth.user?.isVerified) setIsAlreadyVerified(true);
  }, [auth]);

  const genCert = async () => {
    setloadingData(true);
    const today = new Date();
    let dbData = {
      name: auth.user.name,
      date: `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`,
      uniqueId: hashCode(auth.user.name),
      type: 'Life Time Membership',
    };

    if (!auth.user.downloadedCert) {
      await axios.post(
        'https://api.careernaksha.com/api/certification/counsellor',
        dbData
      );
    }

    await axios.post(
      'https://api.careernaksha.com/api/community/users/updateCertStatus',
      {
        phone: auth.user.phone,
      }
    );

    axios
      .post(
        'https://api.careernaksha.com/api/certification/communitycert/create-pdf',
        {
          name: auth.user.name,
          uniqueId: hashCode(auth.user.name),
        }
      )
      .then(() =>
        axios.get('https://api.careernaksha.com/api/certification/fetch-pdf', {
          responseType: 'blob',
        })
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

        saveAs(pdfBlob, 'newPdf.pdf');
        setloadingData(false);
      });

    dispatch(loaduser());
  };

  const dashboard = async () => {
    await axios
      .post(`${backendurl}/com/generateDashboardToken`, {
        id: auth.user._id,
      })
      .then((res) => {
        window.location.href = `${dashboardurl}/?token=${res.data}`;
      })
      .catch((err) => {
        console.log(err.response);
        setAlert('Error while logging into dashboard!');
      });
  };

  const setTags = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = {
      id: localStorage.getItem('userId'),
      isCareerCounsellor: isCareerCounsellor,
      isClinicalCounsellor: isClinicalCounsellor,
      isHrCounsellor: isHrCounsellor,
      industrialCounsellor: isindustrialCounsellor,
      behaviouralCounsellor: isbehaviouralCounsellor,
      academicCounsellor: isacademicCounsellor,
      informationCounsellor: isinformationCounsellor,
      therapyCounsellor: istherapyCounsellor,
      mentalCounsellor: ismentalCounsellor,
    };

    const body1 = {
      id: localStorage.getItem('userId'),
      about: description,
    };

    try {
      const res = await axios.post(
        `https://api.careernaksha.com/api/community/users/tags`,
        body,
        config
      );

      const res1 = await axios.post(
        `https://api.careernaksha.com/api/community/users/about`,
        body1,
        config
      );

      setDescriptionState();
      dispatch(setAlert('Details SuccessFully Updated', 'success'));
      dispatch(loaduser());
    } catch (error) {
      console.log(error);
    }
  };

  const sendRequest = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = {
      product: 'Certification Request',
      name: auth.user?.name,
      email: auth.user?.email,
      phone: auth.user?.phone,
    };

    try {
      const res = await axios.post(
        `${backendurl}/api/community/users/makeCertificationRequest`,
        body,
        config
      );
      if (res.data) {
        dispatch(
          setAlert('Thanks Your Request Will Be Processed Soon', 'success')
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setPsychometricRequest = () => {
    history.push('/test');
  };

  const setCertificationRequest = async () => {
    history.push('/mycodes');
  };

  const level = levels(user && user.points_gamification);

  const percentageLevel = () => {
    const data = (level['currentLevel'] / 6) * 100;
    return `${data}%`;
  };

  const userSpacialization = (key) => {
    switch (key) {
      case 'isCareerCounsellor':
        return 'Career Counselling';
        break;
      case 'isClinicalCounsellor':
        return 'Clinical Counselling';
        break;
      case 'isHrCounsellor':
        return 'HR or Corporate Counselling';
        break;
      case 'industrialCounsellor':
        return 'Industrial Counselling';
        break;
      case 'behaviouralCounsellor':
        return 'Behavioural Counselling';
        break;
      case 'academicCounsellor':
        return 'Academic or Educational Counselling';
        break;
      case 'informationCounsellor':
        return 'Informational Counselling';
        break;
      case 'therapyCounsellor':
        return 'Therapy Based Counselling';
        break;
      case 'mentalCounsellor':
        return 'Mental Health Counselling';
        break;
    }
  };
  return (
    <div>
      {isAuthenticated ? (
        <>
          <div className="new-user-profile">
            <div className="section-1">
              <div className="cover-profile">
                <span className="bg"></span>
                <div className="user-cover-profile">
                  <div className="img">
                    <img
                      src={user.avatar}
                      alt={user.name}
                      style={{ objectFit: 'contain' }}
                    />
                  </div>
                  <div>
                    <h2>
                      {user.name}
                      {user.isVerified && (
                        <Verified
                          style={{ marginRight: '1rem' }}
                          color="primary"
                        />
                      )}
                      <span
                        onClick={() => {
                          setUpdateProfile(true);
                          setTimeout(() => {
                            window.scrollBy(0, 1000);
                          }, 500);
                        }}>
                        (Edit Here)
                      </span>
                    </h2>
                    <h5>{user.email}</h5>
                    <p>{description}</p>

                    <div style={{ marginTop: '.5rem' }}>
                      {user &&
                        user.specialisation &&
                        Object.entries(user.specialisation).map(([k, v]) => {
                          if (v == true) {
                            return <button>{userSpacialization(k)}</button>;
                          }
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-2">
              <LevelStats level={level} />

              <div className="user-action">
                <Button onClick={() => sendRequest()}>
                  Request Certification
                </Button>
                <Button onClick={() => setPsychometricRequest()}>
                  Request Psychometrics
                </Button>
                {!isAlreadyVerified && (
                  <Button
                    onClick={() => {
                      history.push('/request/verify');
                    }}>
                    Request Verification
                  </Button>
                )}
                <Button onClick={() => setCertificationRequest()}>
                  My Test Codes
                </Button>
                <Button onClick={() => genCert()}>
                  Download Membership Certificate
                </Button>
                <Button
                  onClick={() => {
                    setShowActivityPoints((old) => !old);
                    setTimeout(() => {
                      window.scrollBy(0, 500);
                    }, 500);
                  }}>
                  Points
                </Button>
                <Button
                  onClick={() => {
                    dashboard();
                  }}>
                  Dashboard
                </Button>
              </div>
            </div>
          </div>

          <div className="new-post-container">
            <p className="sub-heading">Public Post</p>
            {loading == false
              ? userPost.map((post) => <PostCard post={post} page="Profile" />)
              : 'No Post Found'}

            <p className="sub-heading">Private Post</p>
            {privatePostloading == false
              ? privatePost.map((post) => (
                  <PostCard post={post} page="Profile" />
                ))
              : 'No Post Found'}
          </div>

          {updateProfile && (
            <div className="Update-Profile">
              <h2>Update Profile</h2>
              <div className="row">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Enter Description"
                  multiline
                  minRows={5}
                  inputProps={{ maxLength: 500 }}
                  style={{ width: '90%', margin: '10px 5px' }}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                <h2 style={{ marginTop: '2rem' }}>Specialization</h2>

                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCareerCounsellor}
                          onChange={(e) => {
                            setisCareerCounsellor(!isCareerCounsellor);
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: '15px' }}>
                          Career Counselling
                        </span>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isClinicalCounsellor}
                          onChange={(e) => {
                            setisClinicalCounsellor(!isClinicalCounsellor);
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: '15px' }}>
                          Clinical Counselling
                        </span>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isHrCounsellor}
                          onChange={(e) => {
                            setisHrCounsellor(!isHrCounsellor);
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: '15px' }}>
                          HR or Corporate Counselling
                        </span>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isindustrialCounsellor}
                          onChange={(e) => {
                            setisindustrialCounsellor(!isindustrialCounsellor);
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: '15px' }}>
                          Industrial Counselling
                        </span>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isbehaviouralCounsellor}
                          onChange={(e) => {
                            setisbehaviouralCounsellor(
                              !isbehaviouralCounsellor
                            );
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: '15px' }}>
                          Behavioural Counselling
                        </span>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isacademicCounsellor}
                          onChange={(e) => {
                            setisacademicCounsellor(!isacademicCounsellor);
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: '15px' }}>
                          Academic or Educational Counselling
                        </span>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isinformationCounsellor}
                          onChange={(e) => {
                            setisinformationCounsellor(
                              !isinformationCounsellor
                            );
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: '15px' }}>
                          Informational Counselling
                        </span>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={istherapyCounsellor}
                          onChange={(e) => {
                            setistherapyCounsellor(!istherapyCounsellor);
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: '15px' }}>
                          Therapy Based Counselling
                        </span>
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ismentalCounsellor}
                          onChange={(e) => {
                            setismentalCounsellor(!ismentalCounsellor);
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: '15px' }}>
                          Mental Health Counselling
                        </span>
                      }
                    />
                  </FormGroup>
                </div>

                <div style={{ margin: '1rem 0 0' }}>
                  <Button
                    style={{ margin: 'auto', color: '#42b2a7' }}
                    onClick={() => setUpdateProfile(false)}>
                    Cancel
                  </Button>
                  <Button
                    style={{
                      marginLeft: '2rem',
                      color: 'white',
                      background: '#42b2a7',
                      padding: '.35rem 1rem',
                    }}
                    onClick={() => setTags()}>
                    Update now
                  </Button>
                </div>
              </div>
            </div>
          )}

          {showActivityPoints && (
            <div className="game-rule">
              <h2>Avatars</h2>
              <div className="avatar">
                <div>
                  <img src="../img/avatar/level1.png" alt="avatar" />
                  <p>Level 1 (Upto 50 points)</p>
                </div>
                <div>
                  <img src="../img/avatar/level2.png" alt="avatar" />
                  <p>Level 2 (Points 51 to 500) </p>
                </div>
                <div>
                  <img src="../img/avatar/level3.png" alt="avatar" />
                  <p>Level 3 (Points 501 to 2000)</p>
                </div>
                <div>
                  <img src="../img/avatar/level4.png" alt="avatar" />
                  <p>Level 4 (Points 2001 to 5000)</p>
                </div>
                <div>
                  <img src="../img/avatar/level5.png" alt="avatar" />
                  <p>Level 5 (Points 5001 to 10000)</p>
                </div>
                <div>
                  <img src="../img/avatar/level6.png" alt="avatar" />
                  <p>Level 6 (Points 10001 above)</p>
                </div>
              </div>

              <div className="level_indicator">
                <span style={{ width: percentageLevel() }}></span>
              </div>

              <div className="point-reward">
                <div className="point">
                  <h2>Activities & Points Per User</h2>
                  <p>Account creation 10 points (one time)</p>
                  <p>Profile completion 10 points</p>
                  <p>Download membership 10 points(one time)</p>
                  <p>Referral others 100 points</p>
                  <p>Post creation 15 points</p>
                  <p>Post liking 5 points</p>
                  <p>Post commenting 20 points</p>
                  <p>Social media tag 15 points</p>
                  <p>Psychometrics purchase 50 points</p>
                  <p>Certification purchase 100 points(one time)</p>
                </div>

                <div className="reward">
                  <h2>Rewards</h2>
                  <p>Level 1 - Access to entire community - verification</p>
                  <p>Level 2 - Discounts on psychometrics & certification</p>
                  <p>Level 3 - Free access to recordings, lectures etc.</p>
                  <p>Level 4 - Psychometrics 1 coupon code worth 300/-</p>
                  <p>Level 5 - Psychometrics 2 coupon codes worth 600/-</p>
                  <p>Level 6 - Psychometrics 5 coupon codes worth 1500/-</p>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        "User Isn't Authenticated"
      )}
    </div>
  );
};

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { loaduser })(Profile);
