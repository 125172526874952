import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Pagination from './Pagination';
import { Link } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import PropTypes from 'prop-types';
import { getPosts, addLike } from '../../actions/post';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import { Oval } from 'react-loader-spinner';
import HtmlParser from 'react-html-parser';
import './Posts.css';

function Posts({
  history,
  getPosts,
  post: { posts, loading },
  auth: { isAuthenticated, user },
}) {
  const dispatch = useDispatch();
  // const { isAuthenticated, user } = useSelector((state) => state.auth);

  useEffect(() => {
    getPosts();
  }, [getPosts]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const [arr, setarr] = useState([]);
  const style1 = {
    fontSize: '20px',
    cursor: 'pointer',
  };
  const style2 = {
    fontSize: '20px',
    cursor: 'pointer',
    color: 'blue',
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const changeStyle = () => {
    currentPosts.map((el, i) =>
      el.likes.forEach((n) => {
        if (user) {
          if (n.user == user._id) {
            setarr((oldArray) => [...oldArray, indexOfFirstPost + i]);
          }
        }
      })
    );
  };

  useEffect(() => {
    // console.log(user)
    changeStyle();
  }, [posts, user, currentPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {loading ? (
        <div style={{ marginLeft: '40%', marginTop: '10%' }} className="loader">
          <Oval
            ariaLabel="loading-indicator"
            height={100}
            width={100}
            strokeWidth={5}
            color="#42b76d"
            secondaryColor="#42b7a7"
          />
        </div>
      ) : (
        ''
      )}
      {!loading &&
        currentPosts.map((post, i) => (
          <ListItem alignItems="flex-start" key={i}>
            <ListItemAvatar>
              <img
                alt="Remy Sharp"
                src={post.avatar}
                style={{
                  objectFit: 'contain',
                  height: '50px',
                  width: '50px',
                  marginRight: '15px',
                  borderRadius: '50%',
                }}
              />
            </ListItemAvatar>
            <>
              <ListItemText
                primary={
                  <Link
                    to={{
                      pathname: `/post/${post.slug}`,
                    }}
                    style={{ color: '#000' }}>
                    {post.title}
                  </Link>
                }
                secondary={
                  <div>
                    <div className="my-custom">
                      <Typography
                        sx={{ display: 'inline' }}
                        component="div"
                        paragraph={false}
                        color="text.primary">
                        {HtmlParser(post.text)}
                      </Typography>

                      <br />
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      <ThumbUpAltOutlinedIcon
                        style={
                          arr.includes(indexOfFirstPost + i) ? style2 : style1
                        }
                        onClick={() => {
                          dispatch(addLike(post._id));
                        }}
                      />
                      <span
                        style={{
                          marginLeft: '5px',
                          marginTop: '10px',
                        }}>
                        {post.likes.length}
                      </span>
                      <ModeCommentOutlinedIcon
                        style={{
                          marginLeft: '30px',
                          fontSize: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          history.push(`/post/${post.slug}`);
                        }}
                      />{' '}
                      <span>{post.comments.length}</span>
                    </div>
                    {i != currentPosts.length - 1 && (
                      <Divider style={{ marginTop: '25px' }} />
                    )}
                  </div>
                }
              />
            </>
          </ListItem>
        ))}
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        currentPage
        paginate={paginate}
      />
    </List>
  );
}

Posts.propTypes = {
  getPosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
  auth: state.auth,
});

export default connect(mapStateToProps, { getPosts })(Posts);
