import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import MobLogin from "../../screens/mobileScreen/MobLogin";

const PrivateRoute = ({
	component: Component,
	auth: { isAuthenticated, loading },
	...rest
}) => (
	<Route
		{...rest}
		render={(props) =>
			!isAuthenticated && !loading ? (
				<div>
					<h1
						style={{
							textAlign: "center",
						}}
					>
						Please Login{" "}
					</h1>
					<MobLogin />
				</div>
			) : (
				<Component {...props} />
			)
		}
	></Route>
);

PrivateRoute.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
