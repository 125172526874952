import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import { setAlert } from "../../actions/alert";
import TextField from "@mui/material/TextField";
import "./style.css";

function RequestVerification({ auth, history }) {
  const dispatch = useDispatch();
  const [brand, setbrand] = useState("");
  const [isAlreadyVerified, setIsAlreadyVerified] = useState(false);

  const handleSubmission = async () => {
    const imageForm = document.querySelector("#imageForm");
    const imageInput = document.querySelector("#imageInput");
    const file = imageInput.files[0];

    if (file == undefined) {
      let data_logo = {
        email: auth.user?.email,
        brandLogo:
          "https://ik.imagekit.io/careerNaksha/Solid_white.svg_4-_QI5pt3.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653673224063",
      };

      let name_details = {
        email: auth.user?.email,
        brandName: brand,
      };

      let data_logo_api = await axios.post(
        "https://api.careernaksha.com/api/communityPsychometrics/brandLogo",
        data_logo
      );
      let data_name_api = await axios.post(
        "https://api.careernaksha.com/api/communityPsychometrics/brandName",
        name_details
      );

      sendRequest();
    } else {
      console.log("FILE IS CHOSEN");

      //Get Secure Url From Server
      let post_data = {
        email: auth.user?.email,
      };
      let sec_url = await axios.post(
        "https://api.careernaksha.com/api/communityPsychometrics/s3",
        post_data
      );

      //Post Image S3
      await fetch(sec_url.data.url, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: file,
      });

      const imageUrl = sec_url.data.url.split("?")[0];

      let data_logo = {
        email: auth.user?.email,
        brandLogo: imageUrl,
      };

      let data_logo_api = await axios.post(
        "https://api.careernaksha.com/api/communityPsychometrics/brandLogo",
        data_logo
      );

      let name_details = {
        email: auth.user?.email,
        brandName: brand,
      };

      let data_name_api = await axios.post(
        "https://api.careernaksha.com/api/communityPsychometrics/brandName",
        name_details
      );

      sendRequest();

      //Post Request To My Server To Store Any Extra Data
    }
  };
  const sendRequest = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = {
      product: "Verification Request",
      name: auth.user?.name,
      email: auth.user?.email,
      phone: auth.user?.phone,
    };

    try {
      const res = await axios.post(
        `https://api.careernaksha.com/api/community/users/makeRequest`,
        body,
        config
      );

      if (res.data) {
        console.log(res.data);
        dispatch(
          setAlert("Thanks Your Request Will Be Processed Soon", "success")
        );
        history.push("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkVerified = () => {
    const token = localStorage.getItem("token");
    if (token == null) {
      history.push("/");
    }
  };

  useEffect(() => {
    checkVerified();
    if (auth.user?.isVerified) setIsAlreadyVerified(true);
  }, []);

  if (isAlreadyVerified)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h2 style={{ textAlign: "center", marginTop: "20px" }}>
          You're already Verified
        </h2>
      </div>
    );
  else
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h2 style={{ textAlign: "center", marginTop: "20px" }}>
          Seems Your Account Is Not Verified
        </h2>
        <h4 style={{ textAlign: "center", marginTop: "20px" }}>
          You Can Request Verification From This Page
        </h4>
        <h6 style={{ textAlign: "center", marginTop: "20px" }}>
          Please Enter Brand Name And Upload Logo Image Which you need to
          display in the report.
        </h6>
        <h6 style={{ textAlign: "center", marginTop: "20px" }}>
          Please Upload Logo In 100px X 100px dimension only with transparent
          background.
        </h6>
        <h6 style={{ textAlign: "center", marginTop: "20px" }}>
          To change the logo or brandName displayed in the report later please
          email to nimish@careernaksha.com.
        </h6>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <TextField
            value={brand}
            onChange={(e) => setbrand(e.target.value)}
            InputLabelProps={{
              style: { color: "#000" },
            }}
            style={{ width: "60%", background: "#fff" }}
            id="outlined-basic"
            label="Brand Name"
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <form id="imageForm">
            <input id="imageInput" type="file" accept="image/*" />
          </form>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="text"
            style={{ marginTop: "20px", color: "#000" }}
            onClick={() => handleSubmission()}
          >
            REQUEST VERIFICATION
          </Button>
        </div>
        <h6
          style={{
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          Sample First Page Of Report
        </h6>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src="https://ik.imagekit.io/careerNaksha/Screenshot_2022-05-27_221116_U8jSTUqAK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653669746080"
            className="sample-page"
          />
        </div>
      </div>
    );
}

RequestVerification.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(RequestVerification);
