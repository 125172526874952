import React from 'react';
import './Landing.css';
import Posts from '../../components/layout/Posts';
import Button from '@mui/material/Button';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../actions/alert';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Landing({ history, auth: { isAuthenticated, loading, user } }) {
  const dispatch = useDispatch();
  const routeToPosts = () => {
    history.push('/create-post');
  };

  const query = new URLSearchParams(useLocation().search);
  return (
    <>
      {query.get('refer')}
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="card mb-3 custom-card">
        <div className="row no-gutters">
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">
                Welcome to Counsellors Community by CCI & CareerNaksha
              </h5>
              <p className="card-text">
                This community is for you to practice counselling better.
                Communicate with other fellow counsellors. Ask your questions,
                provide answers and share your thoughts with the community. We
                reward the top counsellors for their unbiased knowledge & ideas.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <img
              src="https://ik.imagekit.io/careerNaksha/Frame_1__2__27UoD81De.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649230937446"
              className="card-img custom-img"
              style={{
                paddingTop: '40px',
                imageRendering: '-webkit-optimize-contrast',
              }}
              alt="career-naksha"
            />
          </div>
        </div>
        <span>
          <Button
            size="small"
            style={{
              color: '#42b7a7',
              borderRadius: '0px',
              borderBottom: '2px solid #42b7a7',
            }}>
            Posts
          </Button>
          <Button
            size="small"
            style={{
              color: '#42b7a7',
            }}
            onClick={() => {
              if (!user) {
                dispatch(setAlert('Please Login', 'danger'));
              } else {
                routeToPosts();
              }
            }}>
            Create
          </Button>

          {user && (
            <Button
              size="small"
              style={{
                color: '#42b7a7',
                borderRadius: '0px',
              }}
              onClick={() => history.push('/private-posts/')}>
              Private Posts
            </Button>
          )}
        </span>
      </div>

      <Posts history={history} />
    </>
  );
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Landing);
