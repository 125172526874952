import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { saveAs } from 'file-saver';
import { setAlert } from '../../actions/alert';
import Pagination from '../../components/layout/Pagination';
import { communityurl } from '../../environment';

function TestCodes({ auth, history }) {
  const [codes, setcodes] = useState([]);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(20);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = codes.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const checkVerified = () => {
    const token = localStorage.getItem('token');
    if (auth.user?.isVerified === false || token == null) {
      history.push('/request/verify');
    }
  };

  const getCodes = async () => {
    let data = {
      email: auth.user?.email,
    };
    let codes = await axios.post(
      'https://api.careernaksha.com/api/communityPsychometrics/getTestCodes',
      data
    );
    console.log(codes.data);
    setcodes(codes.data);
  };

  const downloadPdf = async (code) => {
    try {
      console.log(code);
      setloading(true);
      let data_pdf1 = {
        counsellorName: auth.user?.name,
        phone: auth.user?.phone,
        id: code.toString(),
        logo: auth.user?.brandLogo,
        brand: auth.user?.brandName,
      };
      let gen_pdf = await axios.post(
        'https://api.careernaksha.com/api/communityPsychometrics/test-pdf',
        data_pdf1
      );

      if (gen_pdf.data.message === 'Test Is Not Yet Completed') {
        setloading(false);
        dispatch(setAlert('Test Is Not Yet Completed', 'danger'));
      } else {
        axios
          .get(
            'https://api.careernaksha.com/api/communityPsychometrics/fetch-pdf',
            {
              responseType: 'blob',
            }
          )
          .then((res) => {
            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

            saveAs(pdfBlob, 'Report.pdf');
            setloading(false);
          });
      }

      console.log(gen_pdf);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkVerified();
    getCodes();
  }, [auth]);

  return (
    <div>
      <h2 style={{ textAlign: 'center', marginTop: '20px' }}>MY TEST CODES</h2>
      <h6 style={{ marginTop: '20px' }}>
        After Clicking On Download Report Wait for 30-60 seconds if test is
        completed report will be downloaded
      </h6>
      {loading && (
        <h6 style={{ textAlign: 'center', marginTop: '20px' }}>
          Downloading Report ....
        </h6>
      )}
      <table style={{ marginTop: '20px' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>SR.NO</th>
            <th style={{ textAlign: 'center' }}>ID</th>
            <th style={{ textAlign: 'center' }}>CODE</th>
            <th style={{ textAlign: 'center' }}>ACTION</th>
            <th style={{ textAlign: 'center' }}>DATE</th>
            <th style={{ textAlign: 'center' }}>ASSIGN</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((post, i) => (
            <tr key={post._id}>
              <td style={{ textAlign: 'center' }}>{i + 1}</td>
              <td style={{ textAlign: 'center' }}>{post.uuid}</td>

              <td style={{ textAlign: 'center' }}>{post.test_code}</td>
              <td style={{ textAlign: 'center' }}>
                <Button
                  style={{ color: '#000' }}
                  onClick={() => {
                    downloadPdf(post.uuid);
                  }}>
                  Download Report
                </Button>{' '}
              </td>
              <td style={{ textAlign: 'center' }}>
                {post.createdDate.slice(0, 10)}
              </td>
              <td style={{ textAlign: 'center' }}>
                <a href={`${communityurl}/assigntest?code=${post.test_code}`}>
                  Assign to student
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        postsPerPage={postPerPage}
        totalPosts={codes.length}
        currentPage
        paginate={paginate}
      />
    </div>
  );
}

TestCodes.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(TestCodes);
