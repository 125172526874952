import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import { setAlert } from '../../actions/alert';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const ForgetPassword = ({ onCloseClick }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();

  const resetLink = async (email) => {
    const body = { email: email };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const { data } = await axios.post(
        `https://api.careernaksha.com/api/community/users/recoverpwd`,
        JSON.stringify(body),
        config
      );

      dispatch(setAlert(data, 'success'));
    } catch (error) {
      dispatch(setAlert('Invalid Email Address', 'success'));
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ minWidth: 250, marginTop: 5, maxWidth: '50%' }}>
        <CardContent>
          <p>
            Please enter your e-mail address, You will receive a link to create
            a new password via email
          </p>

          <TextField
            id="outlined-basic"
            label="Enter Email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            style={{ marginBottom: 10, width: '100%' }}
          />
        </CardContent>
        <CardActions>
          <Link to="/forget-password/">
            <Button
              size="small"
              style={{ color: '#42b7a7' }}
              onClick={() => resetLink(email)}>
              SEND RESET LINK
            </Button>
          </Link>
        </CardActions>
      </Card>
    </div>
  );
};

export default ForgetPassword;
