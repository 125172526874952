const Tools = () => {
  return (
    <div className="tools-page-container">
      <h2>Start earning in just 3 simple steps!</h2>
      <div className="tools-page">
        <div>
          <i className="fa-brands fa-wpforms"></i>
          <p>Register & Join as Partner</p>
        </div>
        <div>
          <i className="fa-solid fa-certificate"></i>
          <p>Verification & Activation</p>
        </div>
        <div>
          <i className="fa-solid fa-hand-holding-dollar"></i>
          <p>Start Earning</p>
        </div>
      </div>
      {/* <button>Register Now</button> */}
    </div>
  );
};

export default Tools;
