import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './admin.css';
import Button from '@mui/material/Button';
import { connect} from "react-redux";
import PropTypes from "prop-types";

const DetailedLeads = ({ match,auth,history }) => {
  const [user, userData] = useState(null);
  const checkVerified = () => {
    const token = localStorage.getItem('token');
    console.log(token)
      if(auth.user?.isAdmin === false || token == null){
        history.push("/");
      }
    }

  useEffect(() => {
    checkVerified();
    axios
      .get(
        `https://api.careernaksha.com/api/community/users/getDetailedllLeads/${match.params.id}`
      )
      .then((response) => {
        userData(response.data);
      });
  }, []);

  if (!user) return null;

  return (
    <div className='users-manager' style={{ marginTop: 20 }}>
      <table>
        <thead>
          <tr>
            <th>Lead's Name</th>
            <th>Lead's Phone</th>
            <th>Service Opted</th>
            <th>From</th>
          </tr>
        </thead>
        <tbody>
          {user.map((u) => (
            <tr>
              <td>{u.name}</td>
              <td>{u.phone_no}</td>
              <td>{u.service}</td>
              <td>{u.from}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

DetailedLeads.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});


export default connect(mapStateToProps)(DetailedLeads);
