import axios from "axios";

// ! CONSTANTS

const USER_POST_REQUEST = "USER_POST_REQUEST";
const USER_POST_SUCCESS = "USER_POST_SUCCESS";
const USER_POST_FAIL = "USER_POST_FAIL";

// ! Reducer

export default function (state = {}, action) {
  switch (action.type) {
    case USER_POST_REQUEST:
      return { loading: true, userPost: {} };
    case USER_POST_SUCCESS:
      return { loading: false, userPost: action.payload };
    case USER_POST_FAIL:
      return { loding: false, error: action.payload };
    default:
      return state;
  }
}

// ! action

export const userPostAction = () => async (dispatch) => {
  try {
    dispatch({ type: USER_POST_REQUEST });

    const headers = {
      "x-auth-token": localStorage.getItem("token"),
    };

    const { data } = await axios.get(
      "https://api.careernaksha.com/api/community/posts/post/userPost",
      { headers }
    );
    dispatch({ type: USER_POST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_POST_FAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
